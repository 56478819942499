<template>
  <default-layout>
    <div class="p-4">
      <reset-pass></reset-pass>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ResetPass from '@/components/auth/reset-pass';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ResetPass,
  },
};
</script>
